import React from 'react';
import SEO from '../../components/SEO';
import { Cookie } from 'lucide-react';

const Cookies = () => {
  return (
    <>
      <SEO
        title="Cookie Policy"
        description="Information about how Omaroon uses cookies and similar technologies."
      />

      <div className="pt-24 pb-16">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <Cookie className="h-12 w-12 text-primary-600 dark:text-primary-400 mx-auto mb-4" />
            <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">Cookie Policy</h1>
            <p className="text-gray-600 dark:text-gray-300">Last updated: {new Date().toLocaleDateString()}</p>
          </div>

          <div className="prose dark:prose-invert max-w-none">
            <section className="mb-8">
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">What Are Cookies</h2>
              <p className="text-gray-600 dark:text-gray-300">
                Cookies are small text files that are placed on your device when you visit our website.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">How We Use Cookies</h2>
              <ul className="list-disc pl-6 text-gray-600 dark:text-gray-300">
                <li>Essential cookies for website functionality</li>
                <li>Analytics cookies to understand user behavior</li>
                <li>Preference cookies to remember your settings</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">Managing Cookies</h2>
              <p className="text-gray-600 dark:text-gray-300">
                You can control and manage cookies through your browser settings.
              </p>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cookies;