import React from 'react';
import SEO from '../components/SEO';
import { Shield } from 'lucide-react';

const Legal = () => {
  return (
    <>
      <SEO
        title="Legal Information"
        description="Legal information, privacy policy, terms of service, and cookie policy for Omaroon's PKU medical device solutions."
        keywords="privacy policy, terms of service, cookie policy, legal information, medical device regulations"
      />

      <div className="pt-24 pb-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <Shield className="h-16 w-16 text-primary-600 dark:text-primary-400 mx-auto mb-4" />
            <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
              Legal Information
            </h1>
            <p className="text-xl text-gray-600 dark:text-gray-300">
              Important legal documents and policies
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Privacy Policy */}
            <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg">
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
                Privacy Policy
              </h2>
              <p className="text-gray-600 dark:text-gray-300 mb-4">
                We are committed to protecting your privacy and handling your data with transparency.
              </p>
              <a
                href="/legal/privacy"
                className="text-primary-600 dark:text-primary-400 hover:underline"
              >
                Read our Privacy Policy →
              </a>
            </div>

            {/* Terms of Service */}
            <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg">
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
                Terms of Service
              </h2>
              <p className="text-gray-600 dark:text-gray-300 mb-4">
                Please review our terms of service before using our website or services.
              </p>
              <a
                href="/legal/terms"
                className="text-primary-600 dark:text-primary-400 hover:underline"
              >
                Read our Terms of Service →
              </a>
            </div>

            {/* Cookie Policy */}
            <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg">
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
                Cookie Policy
              </h2>
              <p className="text-gray-600 dark:text-gray-300 mb-4">
                Learn about how we use cookies and similar technologies on our website.
              </p>
              <a
                href="/legal/cookies"
                className="text-primary-600 dark:text-primary-400 hover:underline"
              >
                Read our Cookie Policy →
              </a>
            </div>

            {/* Medical Device Regulations */}
            <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg">
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
                Medical Device Regulations
              </h2>
              <p className="text-gray-600 dark:text-gray-300 mb-4">
                Information about our compliance with medical device regulations and standards.
              </p>
              <a
                href="/legal/regulations"
                className="text-primary-600 dark:text-primary-400 hover:underline"
              >
                View Regulatory Information →
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Legal;