import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight, Award, Shield, Microscope, Heart } from 'lucide-react';
import SEO from '../components/SEO';
import Image from '../components/Image';

const Home = () => {
  const features = [
    {
      icon: Shield,
      title: 'FDA Progress',
      description: 'Pre-Submission strategy meeting with FDA (OOPD) completed for HUD/HDE Designation.',
    },
    {
      icon: Award,
      title: 'NSF Recognition',
      description: 'Received NSF Phase 1 SBIR Award for $256K and completed I-Corps customer discovery.',
    },
    {
      icon: Microscope,
      title: 'Research Excellence',
      description: "Collaborating with Children's Hospital of Orange County (CHOC) for innovative solutions.",
    },
  ];

  return (
    <>
      <SEO
        title="Innovative PKU Medical Device Solutions"
        description="Omaroon is developing groundbreaking medical devices for PKU management, backed by NSF funding and partnerships with leading medical institutions."
        keywords="PKU, Phenylketonuria, medical device, PKU management, amino acids, Phenylalanine, PAH enzyme, CHOC, NSF SBIR"
      />
      
      {/* Hero Section */}
      <section className="pt-24 lg:pt-32 pb-16 bg-gradient-to-br from-primary-50 to-white dark:from-gray-900 dark:to-gray-800">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-4xl md:text-6xl font-bold text-gray-900 dark:text-white mb-6">
              Revolutionizing <span className="text-primary-600 dark:text-primary-400">PKU Care</span>
            </h1>
            <p className="text-xl text-gray-600 dark:text-gray-300 mb-8 max-w-2xl mx-auto">
              Developing innovative medical devices to improve the lives of individuals with Phenylketonuria (PKU).
            </p>
            <div className="flex flex-col sm:flex-row justify-center gap-4">
              <Link
                to="/about"
                className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700"
              >
                Learn More
                <ArrowRight className="ml-2 h-5 w-5" />
              </Link>
              <Link
                to="/contact"
                className="inline-flex items-center justify-center px-6 py-3 border border-primary-600 dark:border-primary-400 text-base font-medium rounded-md text-primary-600 dark:text-primary-400 bg-transparent hover:bg-primary-50 dark:hover:bg-gray-800"
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-16 bg-white dark:bg-gray-900">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div
                key={index}
                className="p-6 bg-gray-50 dark:bg-gray-800 rounded-lg hover:shadow-lg transition-shadow"
              >
                <feature.icon className="h-12 w-12 text-primary-600 dark:text-primary-400 mb-4" />
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
                  {feature.title}
                </h3>
                <p className="text-gray-600 dark:text-gray-300">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Mission Section */}
      <section className="py-16 bg-gray-50 dark:bg-gray-800">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col md:flex-row items-center gap-12">
            <div className="flex-1">
              <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-6">
                Our Mission
              </h2>
              <p className="text-lg text-gray-600 dark:text-gray-300 mb-6">
                PKU affects individuals who cannot break down certain amino acids. Our innovative device aims to revolutionize PKU management, improving quality of life for patients worldwide.
              </p>
              <div className="flex items-center text-primary-600 dark:text-primary-400">
                <Heart className="h-6 w-6 mr-2" />
                <span className="font-medium">Dedicated to Making a Difference</span>
              </div>
            </div>
            <div className="flex-1">
              <Image
                imageKey="mission"
                alt="Medical research and innovation"
                className="rounded-lg shadow-xl w-full h-auto object-cover"
                width="600"
                height="400"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;