import React from 'react';
import { useTheme } from '../context/ThemeContext';

interface Partner {
  id: string;
  name: string;
  website?: string;
}

const partners: Partner[] = [
  { id: 'ucr', name: 'SBDC California Orange county inland empire network', website: 'https://ociesmallbusiness.org/' },
  { id: 'choc', name: "Children's Hospital of Orange County", website: 'https://www.choc.org' },
  { id: 'uci', name: 'UCI Applied Innovation', website: 'https://innovation.uci.edu/' },
  { id: 'ctip', name: 'CTIP Pediatric MedTech Accelerator' },
  { id: 'ulp', name: 'Unversity Lab Partners', website: 'https://www.universitylabpartners.org/' },
  { id: 'dawnbreaker', name: 'Dawnbreaker', website: 'https://www.dawnbreaker.com' },
  { id: 'stanford', name: 'Stanford Medicine', website: 'https://med.stanford.edu' },
  { id: 'npku', name: 'National PKU Alliance', website: 'https://www.npkua.org/' },
  { id: 'nsf', name: 'National Science Foundation: NSF', website: 'https://www.nsf.gov/' }
];
const Partners = () => {
  const { theme } = useTheme();

  return (
    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 items-center justify-items-center">
      {partners.map((partner) => (
        <div
          key={partner.id}
          className="w-full flex items-center justify-center"
        >
          {partner.website ? (
            <a
              href={partner.website}
              target="_blank"
              rel="noopener noreferrer"
              className="group relative w-full aspect-[3/2] flex items-center justify-center p-4 bg-white dark:bg-gray-800 rounded-lg shadow-sm hover:shadow-md transition-shadow"
              aria-label={partner.name}
            >
              <img
                src={`/partners/${partner.id}-${theme}.png`}
                alt={`${partner.name} logo`}
                className="max-w-full max-h-full object-contain filter transition-opacity group-hover:opacity-80"
              />
            </a>
          ) : (
            <div
              className="w-full aspect-[3/2] flex items-center justify-center p-4 bg-white dark:bg-gray-800 rounded-lg shadow-sm"
              aria-label={partner.name}
            >
              <img
                src={`/partners/${partner.id}-${theme}.png`}
                alt={`${partner.name} logo`}
                className="max-w-full max-h-full object-contain"
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Partners;