import { useEffect, useState } from 'react';

interface ImageFallback {
  local: string;
  fallback: string;
}

interface ImageMap {
  [key: string]: ImageFallback;
}

const images: ImageMap = {
  hero: {
    local: '/images/hero.jpg',
    fallback: 'https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?auto=format&fit=crop&q=80'
  },
  research: {
    local: '/images/research.jpg',
    fallback: 'https://images.unsplash.com/photo-1581093458791-4a2b7b366621?auto=format&fit=crop&q=80'
  },
  medical: {
    local: '/images/medical.jpg',
    fallback: 'https://images.unsplash.com/photo-1579684385127-1ef15d508118?auto=format&fit=crop&q=80'
  },
  lab: {
    local: '/images/lab.jpg',
    fallback: 'https://images.unsplash.com/photo-1582719478250-c89cae4dc85b?auto=format&fit=crop&q=80'
  },
  mission: {
    local: '/images/mission.jpg',
    fallback: 'https://images.unsplash.com/photo-1532187863486-abf9dbad1b69?auto=format&fit=crop&q=80'
  },
  about: {
    local: '/images/about.jpg',
    fallback: 'https://images.unsplash.com/photo-1584362917165-526a968579e8?auto=format&fit=crop&q=80'
  }
};

export const useImage = (key: keyof typeof images): string => {
  const [imageSrc, setImageSrc] = useState<string>(images[key].fallback);

  useEffect(() => {
    const checkLocalImage = async () => {
      try {
        const response = await fetch(images[key].local);
        if (response.ok) {
          setImageSrc(images[key].local);
        }
      } catch (error) {
        // If local image fails to load, fallback is already set
        console.debug(`Using fallback image for ${key}`);
      }
    };

    checkLocalImage();
  }, [key]);

  return imageSrc;
};