import React from 'react';
import SEO from '../components/SEO';
import Partners from '../components/Partners';
import { DollarSign, Award, Building, HandHeart } from 'lucide-react';

const Support = () => {
  const funding = [
    {
      icon: DollarSign,
      title: 'NSF SBIR Award',
      amount: '$256K',
      description: 'Phase 1 Small Business Innovation Research grant from the National Science Foundation.',
    },
    {
      icon: Award,
      title: 'CTIP Membership',
      description: 'Portfolio membership with CTIP, providing access to business development services.',
    },
    {
      icon: Building,
      title: 'UCSF-Stanford Program',
      description: 'Access to the Pediatric Device Consortium Accelerator program resources.',
    },
  ];

  return (
    <>
      <SEO
        title="Support & Funding"
        description="Learn about Omaroon's support network and funding achievements in developing innovative PKU medical devices."
        keywords="PKU funding, NSF SBIR, CTIP membership, medical device funding, PKU research support"
      />

      <div className="pt-24 pb-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Hero Section */}
          <div className="text-center mb-16">
            <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
              Support & Funding
            </h1>
            <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
              Our journey is supported by prestigious institutions and organizations committed to advancing medical innovation.
            </p>
          </div>

          {/* Funding Grid */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
            {funding.map((item, index) => (
              <div
                key={index}
                className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg"
              >
                <item.icon className="h-12 w-12 text-primary-600 dark:text-primary-400 mb-4" />
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
                  {item.title}
                </h3>
                {item.amount && (
                  <p className="text-2xl font-bold text-primary-600 dark:text-primary-400 mb-2">
                    {item.amount}
                  </p>
                )}
                <p className="text-gray-600 dark:text-gray-300">{item.description}</p>
              </div>
            ))}
          </div>

          {/* Partners Section */}
          <div className="mb-16">
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white text-center mb-12">
              Our Partners & Supporters
            </h2>
            <Partners />
          </div>

          {/* Support Network */}
          <div className="bg-gray-50 dark:bg-gray-800 rounded-lg p-8 mb-16">
            <div className="flex items-center mb-6">
              <HandHeart className="h-8 w-8 text-primary-600 dark:text-primary-400 mr-4" />
              <h2 className="text-3xl font-bold text-gray-900 dark:text-white">
                Our Support Network
              </h2>
            </div>
            <div className="space-y-4">
              <p className="text-gray-600 dark:text-gray-300">
                We're proud to be supported by leading institutions in medical research and innovation:
              </p>
              <ul className="list-disc list-inside space-y-2 text-gray-600 dark:text-gray-300">
                <li>Children's Hospital of Orange County (CHOC)</li>
                <li>National Science Foundation (NSF)</li>
                <li>UCSF-Stanford Pediatric Device Consortium</li>
                <li>CTIP Pediatric MedTech Accelerator</li>
              </ul>
            </div>
          </div>

          {/* Call to Action */}
          <div className="text-center">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
              Join Our Mission
            </h2>
            <p className="text-gray-600 dark:text-gray-300 mb-6">
              We're always looking for partners and supporters who share our vision for improving PKU care.
            </p>
            <a
              href="/contact"
              className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700"
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Support;