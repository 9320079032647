import React from 'react';
import SEO from '../../components/SEO';
import { FileText } from 'lucide-react';

const Terms = () => {
  return (
    <>
      <SEO
        title="Terms of Service"
        description="Terms and conditions for using Omaroon's website and services."
      />

      <div className="pt-24 pb-16">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <FileText className="h-12 w-12 text-primary-600 dark:text-primary-400 mx-auto mb-4" />
            <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">Terms of Service</h1>
            <p className="text-gray-600 dark:text-gray-300">Last updated: {new Date().toLocaleDateString()}</p>
          </div>

          <div className="prose dark:prose-invert max-w-none">
            <section className="mb-8">
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">Acceptance of Terms</h2>
              <p className="text-gray-600 dark:text-gray-300">
                By accessing and using this website, you accept and agree to be bound by the terms and provision of this agreement.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">Medical Disclaimer</h2>
              <p className="text-gray-600 dark:text-gray-300">
                The content on this website is for informational purposes only. Always consult with qualified healthcare providers regarding medical decisions.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">Intellectual Property</h2>
              <p className="text-gray-600 dark:text-gray-300">
                All content on this website is the property of Omaroon and is protected by intellectual property laws.
              </p>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;