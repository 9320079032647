import React from 'react';
import SEO from '../../components/SEO';
import { Lock } from 'lucide-react';

const Privacy = () => {
  return (
    <>
      <SEO
        title="Privacy Policy"
        description="Omaroon's privacy policy regarding the collection and use of personal information."
      />

      <div className="pt-24 pb-16">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <Lock className="h-12 w-12 text-primary-600 dark:text-primary-400 mx-auto mb-4" />
            <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">Privacy Policy</h1>
            <p className="text-gray-600 dark:text-gray-300">Last updated: {new Date().toLocaleDateString()}</p>
          </div>

          <div className="prose dark:prose-invert max-w-none">
            <section className="mb-8">
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">Information Collection</h2>
              <p className="text-gray-600 dark:text-gray-300">
                We collect information that you provide directly to us, including when you:
              </p>
              <ul className="list-disc pl-6 text-gray-600 dark:text-gray-300">
                <li>Contact us through our website</li>
                <li>Sign up for our newsletter</li>
                <li>Participate in our research studies</li>
                <li>Request information about our medical devices</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">Use of Information</h2>
              <p className="text-gray-600 dark:text-gray-300">
                We use the information we collect to:
              </p>
              <ul className="list-disc pl-6 text-gray-600 dark:text-gray-300">
                <li>Provide and improve our services</li>
                <li>Communicate with you about our products</li>
                <li>Comply with regulatory requirements</li>
                <li>Conduct research and development</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">Data Protection</h2>
              <p className="text-gray-600 dark:text-gray-300">
                We implement appropriate technical and organizational measures to protect your personal information.
              </p>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacy;