import React from 'react';
import { useTheme } from '../context/ThemeContext';

const Logo = () => {
  const { theme } = useTheme();
  
  return (
    <div className="flex items-center gap-2">
      <img 
        src={theme === 'dark' ? '/logo-white.png' : '/logo-dark.png'}
        alt="Omaroon Logo"
        className="h-8 w-auto"
      />
      <span className={`text-2xl font-bold ${
        theme === 'dark' 
          ? 'text-primary-400' 
          : 'text-primary-600'
      }`}>
        Omaroon
      </span>
    </div>
  );
};

export default Logo;