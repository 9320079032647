import React from 'react';
import { useImage } from '../utils/imageLoader';

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  imageKey: string;
}

const Image: React.FC<ImageProps> = ({ imageKey, alt, className, ...props }) => {
  const src = useImage(imageKey as keyof ReturnType<typeof useImage>);

  return (
    <img
      src={src}
      alt={alt}
      className={className}
      loading="lazy"
      {...props}
    />
  );
};

export default Image;