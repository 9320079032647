import React from 'react';
import SEO from '../components/SEO';
import Timeline from '../components/Timeline';
import Image from '../components/Image';
import { Award, Users, Target } from 'lucide-react';

const About = () => {
  const timelineItems = [
    {
      year: '2023',
      title: "UCSF-Stanford Partnership",
      description: "Accepted into the UCSF-Stanford Pediatric Device Consortium Accelerator program.",
    },
    {
      year: '2023',
      title: "CTIP Membership",
      description: "Awarded portfolio membership with CTIP, a Pediatric MedTech Accelerator.",
    },
    {
      year: '2022',
      title: "FDA Pre-Submission",
      description: "Completed strategy meeting with the FDA (OOPD) for HUD/HDE Designation.",
    },
    {
      year: '2021',
      title: "Market Research",
      description: "Received comprehensive market research report from Dawnbreaker.",
    },
    {
      year: '2020',
      title: "NSF SBIR Award",
      description: "Received Phase 1 SBIR Award for $256K from the National Science Foundation.",
    },
    {
      year: '2019',
      title: "CHOC Partnership",
      description: "Established collaboration with Children's Hospital of Orange County.",
    },
  ];

  const team = [
    {
      role: "Research & Development",
      description: "Our R&D team works tirelessly to develop innovative solutions for PKU management.",
    },
    {
      role: "Medical Partnerships",
      description: "Working closely with leading medical institutions to ensure the highest standards.",
    },
    {
      role: "Regulatory Affairs",
      description: "Ensuring compliance and safety through rigorous FDA approval processes.",
    },
  ];

  return (
    <>
      <SEO
        title="About Us"
        description="Learn about Omaroon's mission to revolutionize PKU care through innovative medical devices and our significant milestones in medical technology."
        keywords="PKU medical device, CHOC partnership, NSF SBIR award, FDA submission, medical innovation"
      />

      <div className="pt-24 pb-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Hero Section */}
          <div className="text-center mb-16">
            <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
              About Omaroon
            </h1>
            <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
              Dedicated to revolutionizing PKU care through innovative medical device solutions.
            </p>
          </div>

          {/* Mission Section */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mb-16">
            <div>
              <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-6">
                Our Mission
              </h2>
              <p className="text-gray-600 dark:text-gray-300 mb-6">
                At Omaroon, we're developing groundbreaking solutions for individuals with Phenylketonuria (PKU). Our innovative device aims to transform how PKU is managed, providing better outcomes and improved quality of life for patients worldwide.
              </p>
              <p className="text-gray-600 dark:text-gray-300">
                Through collaboration with leading medical institutions and support from prestigious organizations, we're making significant strides in medical device innovation.
              </p>
            </div>
            <div>
              <Image
                imageKey="about"
                alt="Medical innovation and research"
                className="rounded-lg shadow-xl w-full h-full object-cover"
              />
            </div>
          </div>

          {/* Timeline Section */}
          <div className="mb-16">
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-8 text-center">
              Our Journey
            </h2>
            <Timeline items={timelineItems} />
          </div>

          {/* Team Section */}
          <div>
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-8 text-center">
              Our Team
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {team.map((member, index) => (
                <div
                  key={index}
                  className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg text-center"
                >
                  <Users className="h-12 w-12 text-primary-600 dark:text-primary-400 mx-auto mb-4" />
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
                    {member.role}
                  </h3>
                  <p className="text-gray-600 dark:text-gray-300">{member.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;