import React from 'react';

interface TimelineItem {
  year: string;
  title: string;
  description: string;
}

interface TimelineProps {
  items: TimelineItem[];
}

const Timeline: React.FC<TimelineProps> = ({ items }) => {
  return (
    <div className="relative">
      {/* Vertical line */}
      <div className="absolute left-4 md:left-1/2 h-full w-0.5 bg-primary-200 dark:bg-primary-800 transform -translate-x-1/2"></div>

      <div className="space-y-12">
        {items.map((item, index) => (
          <div key={index} className={`relative flex items-center ${index % 2 === 0 ? 'md:flex-row-reverse' : 'md:flex-row'}`}>
            {/* Content */}
            <div className="flex-1 md:w-1/2 ml-12 md:ml-0 md:px-8">
              <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
                <span className="text-sm font-semibold text-primary-600 dark:text-primary-400">{item.year}</span>
                <h3 className="text-lg font-bold text-gray-900 dark:text-white mt-1">{item.title}</h3>
                <p className="text-gray-600 dark:text-gray-300 mt-2">{item.description}</p>
              </div>
            </div>

            {/* Dot */}
            <div className="absolute left-4 md:left-1/2 w-4 h-4 bg-primary-600 dark:bg-primary-400 rounded-full transform -translate-x-1/2"></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Timeline;